#root > nav > div > div.align-middle > div > div > div > span.split {
    background-color: rgb(248, 172, 115);
    color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
}

#root > nav > div > div.align-middle > div > div > div > span.count {
    padding: 5px;
    font-weight: bold;
}

.countdown-timer-background {
    background-color: rgb(248, 172, 115);
    color: black;
    padding: 1px;
    padding-right: 8px;
    padding-left: 8px;
    font-family: 'Segoe UI';
    font-style: 'bold';
}
