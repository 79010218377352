body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fafafa;
    overflow-y: auto;

    -webkit-print-color-adjust: exact;
}

.ui-tooltip {
    max-width: 3000px;
}

.font-weight-bold {
    font-weight: 600 !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.active {
    background: #8be9ba;
    color: #000;
}

@media print {
    tbody > tr > td.table-success {
        background-color: #c3e6cb !important;
    }

    tbody > tr > td.table-warning {
        background-color: #ffeeba !important;
    }

    tbody > tr.table-info > td {
        background-color: #bee5eb !important;
    }

    tbody > tr.table-secondary > td {
        background-color: #b3b7bb !important;
    }
}

input[type='checkbox'] {
    width: 15px;
    height: 15px;
}

html {
    height: 100%;
}

body {
    height: 100%;
}

#root {
    height: 100%;
}

.full-height {
    height: 100%;
}

.table-outer-border {
    border-width: 1px;
    border-color: rgb(222, 226, 230);
    border-style: solid;
}
