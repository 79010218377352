.center {
    display: flex;
    justify-content: center;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.student-page {
    max-height: 600px;
}
