.sticky-left-selection {
    position: -webkit-sticky !important;
    position: sticky !important;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: white;
}

.sticky-left-header {
    position: -webkit-sticky !important;
    position: sticky !important;
    left: 0;
    top: 0;
    /* z-index: 1; */
    background-color: white;
}

.bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
}

.unassigned-group {
    background-color: #80d9ff;
}

.assigned-group {
    background-color: #9fe0df;
}

.group-table {
    max-height: 600px;
}

.dot-not-mastered {
    height: 25px;
    width: 25px;
    background-color: #cccccc;
    border-color: #cccccc;
    border-radius: 50%;
    display: inline-block;
}

.dot-mastered {
    height: 25px;
    width: 25px;
    background-color: green;
    border-color: green;
    border-radius: 50%;
    display: inline-block;
}

.dot-success {
    height: 25px;
    width: 25px;
    background-color: #c3e6cb;
    border-color: #c3e6cb;
    border-radius: 50%;
    display: inline-block;
}

.dot-warning {
    height: 25px;
    width: 25px;
    background-color: #ffeeba;
    border-color: #ffeeba;
    border-radius: 50%;
    display: inline-block;
}

.dot-previously-mastered {
    height: 25px;
    width: 25px;
    background-color: purple;
    border-color: purple;
    border-radius: 50%;
    display: inline-block;
}

.dot-not-asses-yet {
    height: 25px;
    width: 25px;
    background-color: #ffffff;
    border-color: #ffffff;
    border-radius: 50%;
    display: inline-block;
}

/* .test {
  position: absolute;
  height: 100px;
  width: 100px;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
}  */
