.center {
    display: flex;
    justify-content: center;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.card-height {
    min-height: 100px;
}

.card-header-height {
    min-height: 60px;
}

.card-body-height {
    min-height: 60px;
}

.interview-question-color {
    color: #404040;
}

.card-mastered {
    background-color: #e2fae8;
    border-color: #e2fae8;
}

.card-not-mastered {
    background-color: #fdf6dd;
    border-color: #fdf6dd;
}

.card-teacher-override {
    background-color: rgb(197, 226, 236);
    border-color: rgb(197, 226, 236);
}

.min-width-button {
    min-width: 175px;
}

.image-tool {
    max-height: 200px;
}

.header-in-left-center {
    height: 90%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
}

.header-in-center-div {
    height: 90%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.student-list-key-concept > div:nth-of-type(odd) {
    background: #eafaee;
}

.key-concept-interview-heading {
    background: bisque;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

/* .microcopy-box {
  background-color: white;
  border-style: solid;
  border-width: 0.5px;
} */

/* .test {
  background-color: none;
  border-style: none;
  border-color: none;
} */

.red-cell {
    background-color: red;
}

/* .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
} */

.label-bold-style {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 2px;
}

.label-subtitle {
    font-size: smaller;
    margin-bottom: 2px;
}

.label-style {
    font-size: 16px;
}

.small-label-style {
    font-size: 13px;
}

.small-label-bold-style {
    font-size: 13px;
    font-weight: bold;
}

.clock {
    width: 43px;
    height: 43px;
}
