.faq-subheading {
    color: rgb(231, 107, 47);
    font-weight: 700;
}

.row-title {
    font-size: 18px;
    color: rgb(40, 159, 225);
    font-weight: 500;
}

.row-content-text {
    font-size: 18px;
    color: rgb(40, 159, 225);
}

.btn:focus {
    outline: none !important;
    outline-offset: none !important;
}

.br {
    display: block;
    margin-bottom: 7px;
    /* font-size:5px;
    line-height: 10px; */
}

/* 
br {
    display: block;
    margin-bottom: 50px;
    font-size:50px;
    line-height: 20px;
} */

.faq-content-inner-heading {
    font-size: 16px;
    color: rgb(37, 96, 163);
    font-weight: 700;
    line-height: 40px;
}
