a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}
@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.navbar-custom {
    background-color: #009fe3;
    /* background-color: #8d25b3; */
    /* background-color: #9B51E0; */
}
/* change the brand and text color */
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
    color: rgba(255, 255, 255, 0.8);
}
/* change the link color */
.navbar-custom .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
}
/* change the color of active or hovered links */
.navbar-custom .nav-item.active .nav-link,
.navbar-custom .nav-item:hover .nav-link {
    color: #ffffff;
}

.logo-mini {
    /* ratio of 5:1 */
    width: 40px;
    height: 40px;
}

.logo {
    /* ratio of 5:1 */
    /* width:180px;
    height:36px;  */
}
