.textContainer {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}

/* :root{
  background-color: white;
}
 */

/* .testimonial-author {
    color: #14C2E7;
    font-family: 'lobster_1.4regular';
    font-size: 35pt;
    height: auto;
    line-height: 0;
    position: absolute;
    text-shadow: 1px 1px #000000;
    width: 850px;
    } */

/* Will be applied in Homepage */
/* .html .testimonial-author {
    background-color: #14C2E7;
    } */

/* 
  .testimonial-author {
    color: blue; */
