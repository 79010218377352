.dashboard-image {
    object-fit: contain;
    max-height: 140px;
    max-width: 100%;
    transition-duration: 0.3s;
    cursor: pointer;
    max-width: 100%;
}

.dashboard-image:hover {
    transform: scale(1.08);
}

.card-body-container {
    min-height: 200px;
}

.logo {
    /* ratio of 5:1 */
    width: 400px;
    height: 75px;
}

.custom-modal-style {
    width: 1300px;
    height: 800px;
    position: absolute;
}
