/* overview and component report container */
.report-container {
    width: 100%;
    /* overflow-x: scroll; */
    /* max-height: 750px; */
}

#stickyHeader {
    position: fixed;
    top: 0;
}

/* 
 @media all { 
   @page { margin: 0 !important; border: 0 !important; padding: 0 !important;  width:100%  !important} 
   body
{
  margin: 0  !important;
  border: 0  !important;
  padding: 0  !important;
  width:100%  !important;
}
html
{
  margin: 0  !important;
  border: 0  !important;
  padding: 0  !important;
  width:100%  !important;
}
:root
{
  margin: 0  !important;
  border: 0  !important;
  padding: 0  !important;
  width:100%  !important;
}
 } */
/* @media all {
    .report-container {
      overflow-x: visible;
      transform-origin: left top;
      transform: rotate(90deg) translateY(-100%);
    }
  } 

/* .report-container-print-view {
  width: 100%;
  /* max-height: 750px; */
/* overflow-x: scroll; */

/* .transform-pos{
  transform-origin: center top;
} */

/* .rotate-table-print-view {
  transform-origin: left top;
  transform: rotate(90deg) translateY(-100%);
} */

/* overview report should have min 60px */
.report-column-cell {
    height: 50px;
    min-width: 50px;
}

/* component report should have min widht of 100px */
.component-column-cell {
    height: 50px;
    min-width: 150px;
    max-width: 150px;
}

/* Student names max size */
.offset-studentName {
    width: 20%;
    min-width: 200px;
    max-height: 50px;
    background: lightgray;
}

/* when KC is not selected, the widht of studentName is max 20% width */
.first-column-max-width {
    width: 20%;
}

/* Dropdown list should be on top of table header .tableHeader */
#masterSelect {
    z-index: 2 !important;
}

/* Make table header sticky: overview report and component report */
.tableHeader {
    position: sticky;
    top: 0;
    overflow: auto;
    background-color: white;
    z-index: 1;
}

.stickyHeader {
    position: absolute;
    top: 0;
    z-index: 3 !important;
    overflow: hidden;
}

.tableHeader-print-view {
    top: 0;
    overflow: auto;
    background-color: white;
    z-index: 1;
}

/* Sits under table header: overview report and component report */
#table {
    position: relative;
    width: 20%;
    background-color: white;
    z-index: 0;
    /* page-break-inside: avoid; */
}

/* table.print-friendly tr td{
   page-break-inside: avoid; 
}  */

.avoid {
    page-break-inside: avoid !important;
    margin: 4px 0 4px 0; /* to keep the page break from cutting too close to the text in the div */
}

/* table.print-friendly tr td, table.print-friendly tr th {
  page-break-inside: avoid;
} */

/* #table tr th{
  page-break-inside: avoid;
}  */

.strand-header-colour {
    color: black;
    background-color: rgb(218, 209, 233);
}

.nsw-table-first-col-width {
    width: 20%;
}

.nsw-table-other-col-width {
    width: 40%;
}

/* .thicker-table-lines {
  border-style: solid;
  border-width: 5px;
  } */

#individual-table td {
    border-style: solid;
    border-width: 1px;
    border-color: darkgrey;
}

#individual-table th {
    border-style: solid;
    border-width: 1px;
    border-color: darkgrey;
}
